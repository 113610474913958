//Unit System for Price Calculation (KM or MILE "case sensitive")
var unit_system = "KM";
//UNIT FARES for VEHICLE TYPES
var moto_unitPrice = 1.00; //fare per unit for motorcycle
var moto_minPrice = 5.00; //minimum fare for motorcycle
var car_unitPrice = 1.25;
var car_minPrice = 10.00;
var minivan_unitPrice = 1.50;
var minivan_minPrice = 15.00;
var luxury_unitPrice = 2.00;
var luxury_minPrice = 20.00;
// If customer select Ride Type as Round Trip than system is multiplies taxi fare with double. For changing multiplier number, please find the following code line and change the number 2.
var roundTrip_multiplier = 2;
//Coordinates of your city for homepage map
var myLatitude = 40.7193573;
var myLongitude = -74.0205823;

////////////////////////////////////////////////////////////
// DO NOT EDIT AFTER THIS LINE OTHER THAN THE TRANSLATION //
////////////////////////////////////////////////////////////

function formatCurrency(price) {
	if($("#transfer").length == 0) { //if transfer type selection isn't exist
    transferType=7;
  }else{
    transferType=$('#transfer').val();
  }
	//transferType = document.fm.transfer.options[document.fm.transfer.selectedIndex].value;
	if (transferType == 2) price = price * roundTrip_multiplier;

	document.getElementById('bookStatus').innerHTML = "";
	document.getElementById('normFare').innerHTML = "<h2 style='padding:0 !important'><span style='color:#996e06'>Taxi Fare:</span> $<span class='count' id='aboneSpan'><b>" + Math.ceil(price) + "</b></span></h2>";

	$("input#costVal").val(Math.ceil(price));
	$("#getTaxi").attr("disabled", false);
	$("#mapLoader").slideUp("fast");
	$("#normFare").slideDown("slow");
	$('.count').counterUp({
		delay: 100,
		time: 500
	});

	if (transferType == '1' || transferType == '7') {
		$("#imgDirection").attr("src","images/direct-arrow.svg");
	}
	if (transferType == '2') {
		$("#imgDirection").attr("src","images/bidirectional-arrows.svg");
	}
	document.getElementById('aPoint').innerHTML = $(".bf3 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text();
	document.getElementById('bPoint').innerHTML = $(".bf4 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text();
	$("#getTaxi").attr("disabled", false);
	$("#mapLoader").slideUp("fast");
	$("#route").slideDown("slow");
	$("#bookingButton").slideDown("slow");
}

function initMap() {
	var directionsService = new google.maps.DirectionsService;
	var directionsDisplay = new google.maps.DirectionsRenderer;
	var minZoomLevel = 9; //11
	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: 11,
		zoomControl:true,
		scrollwheel: false,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		disableDoubleClickZoom: true,
		center: {lat: myLatitude, lng: myLongitude},
		styles: [
					    {
				        "featureType": "road",
				        "elementType": "geometry",
				        "stylers": [
			            {
			              "lightness": 100
			            },
			            {
			              "visibility": "simplified"
			            }
				        ]
					    },
					    {
				        "featureType": "water",
				        "elementType": "geometry",
				        "stylers": [
			            {
			              "visibility": "on"
			            },
			            {
			              "color": "#C6E2FF"
			            }
				        ]
					    },
					    {
				        "featureType": "poi",
				        "elementType": "geometry.fill",
				        "stylers": [
			            {
			              "color": "#C5E3BF"
			            }
				        ]
					    },
					    {
				        "featureType": "road",
				        "elementType": "geometry.fill",
				        "stylers": [
			            {
			              "color": "#D1D1B8"
			            }
				        ]
					    }
						]
	});

	// Bounds for North America
	var strictBounds = new google.maps.LatLngBounds(
	new google.maps.LatLng(40.463850, -74.347273),
	new google.maps.LatLng(40.943029, -73.521203));

	//directionsDisplay.setMap(map);
	var trafficLayer = new google.maps.TrafficLayer();
	trafficLayer.setMap(map);

	//ekrani kaydir
	setTimeout(function() {$('html,body').animate({scrollTop: 0}, 500)}, 1500);

	// Listen for the dragend event
	google.maps.event.addListener(map, 'dragend', function () {
		if (strictBounds.contains(map.getCenter())) return;

		// We're out of bounds - Move the map back within the bounds

		var c = map.getCenter(),
		x = c.lng(),
		y = c.lat(),
		maxX = strictBounds.getNorthEast().lng(),
		maxY = strictBounds.getNorthEast().lat(),
		minX = strictBounds.getSouthWest().lng(),
		minY = strictBounds.getSouthWest().lat();

		if (x < minX) x = minX;
		if (x > maxX) x = maxX;
		if (y < minY) y = minY;
		if (y > maxY) y = maxY;

		map.setCenter(new google.maps.LatLng(y, x));
	});

	// Limit the zoom level
	google.maps.event.addListener(map, 'zoom_changed', function () {
	  if (map.getZoom() < minZoomLevel) map.setZoom(minZoomLevel);
	});
}

function setError(id, msg){
	$(id).addClass('has-error');
	$("#bookStatus").slideUp("fast");
	document.getElementById('bookStatus').innerHTML = msg;
	$("#getTaxi").attr("disabled", false);
	$("#mapLoader").slideUp("fast");
	$("#normFare").slideUp("slow");
	$("#route").slideUp("slow");
	$("#bookingButton").fadeOut("fast");
	$("#bookStatus").slideDown("slow");
}

function initDirections(start, end, post, transfer) {
	//calculate price start
	to = end;
	from = start;

	postType = post;
	transferType = transfer;

	$(".bf1 .row, .bf2 .row, .bf3 .row, .bf4 .row").removeClass('has-error');

  if ((from == '' && to == '') || (from == 'From?' && to == 'Where?')) {
		$("#bookStatus").slideUp("fast");
    $("#getTaxi").attr("disabled", false);
		$("#mapLoader").slideUp("fast");
		$("#normFare").slideUp("slow");
    $("#route").slideUp("slow");
		$("#bookingButton").fadeOut("fast");

    return;
  } else if (from == '' || from == 'From?') {
		setError('.bf3 .row', '<h7>Enter the Pick-up Destination</h7>')
    return;
  } else if (to == '' || to == 'Where?') {
    setError('.bf4 .row', '<h7>Enter the Drop-off Destination</h7>')
    return;
  } else if (transferType == '0') {
		setError('.bf2 .row', '<h7>Select Ride Type</h7>')
    return;
  } else if (postType == '0') {
    setError('.bf1 .row', '<h7>Select Vehicle Type</h7>')
    return;
  } else {
  	$("#bookStatus").slideUp("slow");
  }

	//show preloader
	$("#getTaxi").attr("disabled", true);
	$("#mapLoader").slideDown("fast");

	//calculate price end
	var directionsService = new google.maps.DirectionsService;
	var directionsDisplay = new google.maps.DirectionsRenderer({
	  polylineOptions: {
	  strokeColor: "green",
		strokeOpacity: 0.6 ,
		strokeWeight: 7
	  }
	});

	$('input[id="startVal"]').val(start);
	$('input[id="endVal"]').val(end);

	var minZoomLevel = 5;
	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: minZoomLevel,
		zoomControl:true,
		scrollwheel: false,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false,
		disableDoubleClickZoom: true,
		center: {lat: myLatitude, lng: myLongitude},
		styles: [
					    {
				        "featureType": "road",
				        "elementType": "geometry",
				        "stylers": [
			            {
			              "lightness": 100
			            },
			            {
			              "visibility": "simplified"
			            }
				        ]
					    },
					    {
				        "featureType": "water",
				        "elementType": "geometry",
				        "stylers": [
			            {
			              "visibility": "on"
			            },
			            {
			              "color": "#C6E2FF"
			            }
				        ]
					    },
					    {
				        "featureType": "poi",
				        "elementType": "geometry.fill",
				        "stylers": [
			            {
			              "color": "#C5E3BF"
			            }
				        ]
					    },
					    {
				        "featureType": "road",
				        "elementType": "geometry.fill",
				        "stylers": [
			            {
			              "color": "#D1D1B8"
			            }
				        ]
					    }
						]
	});

	directionsDisplay.setMap(map);

	// Bounds for North America
	var strictBounds = new google.maps.LatLngBounds( //LatLngBounds([sw, ne]) Constructs a rectangle from the points at its south-west and north-east corners.
	new google.maps.LatLng(26.424053, -127.879493),
	new google.maps.LatLng(42.821269, -52.039623));

	// Listen for the dragend event
	google.maps.event.addListener(map, 'dragend', function () {
		if (strictBounds.contains(map.getCenter())) return;

		// We're out of bounds - Move the map back within the bounds
		var c = map.getCenter(),
		x = c.lng(),
		y = c.lat(),
		maxX = strictBounds.getNorthEast().lng(),
		maxY = strictBounds.getNorthEast().lat(),
		minX = strictBounds.getSouthWest().lng(),
		minY = strictBounds.getSouthWest().lat();

		if (x < minX && map.getZoom()<=minZoomLevel) x = minX;
		if (x > maxX && map.getZoom()<=minZoomLevel) x = maxX;
		if (y < minY && map.getZoom()<=minZoomLevel) y = minY;
		if (y > maxY && map.getZoom()<=minZoomLevel) y = maxY;

		map.setCenter(new google.maps.LatLng(y, x));
	});

	// Limit the zoom level
	google.maps.event.addListener(map, 'zoom_changed', function () {
	  if (map.getZoom() < minZoomLevel) map.setZoom(minZoomLevel);
	});

	calculateAndDisplayRoute(directionsService, directionsDisplay, start, end, post, transfer);
}

function calculateAndDisplayRoute(directionsService, directionsDisplay, start, end, post, transfer) {
	to = end;
	from = start;
	postType = post;
	transferType = transfer;
	directionsService.route({
		origin: start,
		destination: end,
		travelMode: 'DRIVING'
	}, function(response, status) {
		if (status === 'OK') {
			directionsDisplay.setDirections(response);
		} else {
			//window.alert('Directions request failed due to ' + status);
			showMsg('fa fa-warning', 'Unable to Get Location Information!', '<span style="color:#c30">There was an error getting the location information. Please contact us to create your order.</span><h4><b>+1 555-444-9333</b></h4>7/24 Taxi Line', '<i class="fa fa-phone"></i>&nbsp; CALL', 'btn-green',"location.href='javascript:window.open(\"tel:+15554449333\", \"_system\")'",'Close','btn-dark','this.close()');
			$("#getTaxi").attr("disabled", false);
			$("#mapLoader").slideUp("fast");
			$("#normFare").slideUp("slow");
			$("#route").slideUp("slow");
			$("#bookingButton").fadeOut("fast");
			return;
		}
	});

	if(unit_system == "KM"){
		unit_system = google.maps.UnitSystem.METRIC;
	}else if(unit_system == "MILE"){
		unit_system = google.maps.UnitSystem.IMPERIAL;
	}

	var directionsDistance = new google.maps.DistanceMatrixService;
	directionsDistance.getDistanceMatrix({
		origins: [start],
		destinations: [end],
		travelMode: 'DRIVING',
    unitSystem: unit_system,
    avoidHighways: false,
    avoidTolls: false,
		avoidFerries: false
	}, function(response, status) {
		if (status === 'OK') {
			var results = response.rows[0].elements;
			//alert(results[0].distance.text);
			var distance = results[0].distance.text.split(' ')[0];
			if(distance>=1.000){distance = distance.replace('.','');}
			distance = distance.replace(',','.');
			priceCalculation(distance, start, end, post, transfer);
			//alert("Distance: " + distance + " / Duration: " + results[0].duration.text);
		} else {
			//alert('Directions request failed due to ' + status);
		}
	});
}

function priceCalculation(distance, start, end, post, transfer) {
	$('input[id="postVal"]').val(post);
	$('input[id="transferVal"]').val(transfer);

	var normPrice = 0;

	if(distance >= 1) {
		if(post==1){
			normPrice = distance * moto_unitPrice;
			if(normPrice < moto_minPrice || !normPrice){ //set minimum fare
				normPrice = moto_minPrice;
			}
		}else if(post==2 || post==7){
			normPrice = distance * car_unitPrice;
			if(normPrice < car_minPrice || !normPrice){ //set minimum fare
				normPrice = car_minPrice;
			}
		}else if(post==3){
			normPrice = distance * minivan_unitPrice;
			if(normPrice < minivan_minPrice || !normPrice){ //set minimum fare
				normPrice = minivan_minPrice;
			}
		}else if(post==4){
			normPrice = distance * luxury_unitPrice;
			if(normPrice < luxury_minPrice || !normPrice){ //set minimum fare
				normPrice = luxury_minPrice;
			}
		}

		//alert("Distance: "+distance+" - Price: "+normPrice);
	}else{
		if(post==1){
			normPrice = moto_minPrice;
		}else if(post==2){
			normPrice = car_minPrice;
		}else if(post==3){
			normPrice = minivan_minPrice;
		}else if(post==4){
			normPrice = luxury_minPrice;
		}
	}

	//scroll page
	if($("#priceCalc").length > 0) {
		$('html, body').animate({
	    scrollTop: $("#priceCalc").offset().top-30
	  }, 1000);
	}else{
		if($(window).width()<=768){
			tOffset=30;
		}else{
			tOffset=150;
		}
		$('html, body').animate({
	    scrollTop: $("#priceCalc1").offset().top-tOffset
	  }, 1000);
	}

	if($(window).width()>=768 && $('input[id="slideVal"]').val()=="slide"){
		$(".booking-form-wrapper").animate({marginTop: '400px'}, "slow");
	}

	var fromT = $(".bf3 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text();
	fromT = fromT.replace("<b>", "");
	fromT = fromT.replace("</b>", "");
	$(".bf3 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text(fromT);

	var toT = $(".bf4 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text();
	toT = toT.replace("<b>", "");
	toT = toT.replace("</b>", "");
	$(".bf4 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text(toT);

	formatCurrency(normPrice); //print the fare to screen function
}

jQuery(document).ready(function($) {
	$('.counter').counterUp({
		delay: 50,
		time: 500
	});
	initSelects();
});
