(function($) {

jQuery(window).load(function(){
	//show-hide preloader
  jQuery("#preloader").delay(500).fadeOut(1000, function(){
		for(i=1; i<4; i++){
			$(".item" + i).removeClass("item" +i);
		}
		$(this).remove();
	});
	//modal window defaults
	jconfirm.defaults = {
		onOpenBefore: function () {document.body.style.overflow = 'hidden';},
		onDestroy: function () {document.body.style.overflow = 'auto';}
	};
	//activate date and time picker
	$("#datepicker").datepicker({minDate: 0, maxDate: '+1W', dateFormat: 'dd-mm-yy'});
	$('#timepicker').timepicker({ 'scrollDefault': 'now', 'timeFormat': 'H:i' });
	//activate tooltip
	$('[data-toggle="tooltip"]').tooltip();
	//auto show-hide tooltip for 5 seconds
	$('a').tooltip({
  	trigger: 'manual'
	});
	$('a').tooltip('show');
	setTimeout(function(){ $('a').tooltip('hide'); }, 3000);
});
})(jQuery);
	try {
		$(window).stellar({
		horizontalScrolling: false,
		});
	} catch(err) {
		}
	try {
		if ($(".animated")[0]) {
        $('.animated').css('opacity', '1');
			}
			$('.triggerAnimation').waypoint(function() {
        var animation = $(this).attr('data-animate');
        $(this).css('opacity', '');
        $(this).addClass("animated " + animation);
			},
        {
          offset: '80%',
          triggerOnce: true
        }
			);
	} catch(err) {
}

//activate navigation
$(function() {
	$( '#dl-menu' ).dlmenu({
		animationClasses : { classin : 'dl-animate-in-2', classout : 'dl-animate-out-2' }
	});
});

//minimize-maximize booking form
function minmax(minmax){
	if(minmax=="min"){
		$('.bf1, .bf2, .bf3, .bf4, .bf5, .bf6, .bf7, .bf8').hide();
		$('.minmax').css('display','none');
		$('#maximize').show();
		$('.form-wrap').width('50%');
	}else{
		$('.bf1, .bf2, .bf3, .bf4, .bf5, .bf6, .bf7, .bf8').fadeIn('fast');
		$('.minmax').css('display','block');
		$('#maximize').hide();
		$('.form-wrap').width('100%');
	}
}

function formatRepo(repo) {
	if (repo.loading) return repo.text;

	var markup = "<div class='select2-result-repository clearfix'>" +
		//"<div class='select2-result-repository__avatar'><img src='" + repo.owner.avatar_url + "' /></div>" +
		"<div class='select2-result-repository__meta'>" +
			"<div class='select2-result-repository__title' style='font-weight:bold'>" + repo.full_name + "</div>";

	if (repo.description) {
		markup += "<div class='select2-result-repository__description'>" + repo.description + "</div>";
	}

	markup += "</div></div>";

	return markup;
}

function formatRepoSelection(repo) {
	return repo.description || repo.text;
}

function initSelects() {
	$.fn.select2.defaults.set("theme", "bootstrap");
	$('select').select2();

	// $(".start-ajax, .end-ajax").select2({
	// 	width : null,
	// 	containerCssClass: ':all:',
	// 	//allowClear: true,
	// 	ajax: {
	// 		url: "farecalculate.php",
	// 		dataType: 'json',
	// 		type: 'POST',
	// 		delay: 250,
	// 		data: function (params) {
	// 			return {
	// 				q: params.term, // search term
	// 				page: params.page
	// 			};
	// 		},
	// 		processResults: function (data, params) {
	// 			// parse the results into the format expected by Select2
	// 			// since we are using custom formatting functions we do not need to
	// 			// alter the remote JSON data, except to indicate that infinite
	// 			// scrolling can be used
	// 			params.page = params.page || 1;

	// 			return {
	// 				results: data.items,
	// 				pagination: {
	// 					more: (params.page * 30) < data.total_count
	// 				}
	// 			};
	// 		},
	// 		cache: true
	// 	},
	// 	escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
	// 	minimumInputLength: 1,
	// 	templateResult: formatRepo,
	// 	templateSelection: formatRepoSelection
	// });

	$(".select2-single").select2({
		width : null,
		containerCssClass: ':all:',
		minimumResultsForSearch: Infinity
	});
	//bug fixes: https://github.com/select2/select2/issues/4614
	// $(".start-ajax").data("select2").on("results:message", function () {
    // this.dropdown._positionDropdown();
	// 	this.dropdown._resizeDropdown();
	// });
	// $(".end-ajax").data("select2").on("results:message", function () {
    // this.dropdown._positionDropdown();
	// 	this.dropdown._resizeDropdown();
	// });
}

$(".select2-single, .start-ajax, .end-ajax").on( "change", function() {
	if ( $(this).parents( "[class*='has-']" ).length) {
		var classNames = $(this).parents("[class*='has-']")[0].className.split(/\s+/);

		for (var i = 0; i < classNames.length; ++i) {
			if ( classNames[i].match("has-") ) {
				$("body > .select2-container").addClass(classNames[i]);
				$("body > .select2-container").removeClass("has-error");
			}
		}
	}

	if(($('input[id="startVal"]').val()=="" || $('input[id="endVal"]').val()=="") || $('#'+$(this).attr('id')).val()!="9"){
	  if($(this).attr('id') == "start"){
      $('input[id="startVal"]').val($('#'+$(this).attr('id')).val());
    }else if($(this).attr('id') == "end"){
      $('input[id="endVal"]').val($('#'+$(this).attr('id')).val());
    }
	}else{ //refreshes the map if location is selecting for second time
	   $('input[id="startVal"]').val($("#start").val());
	   $('input[id="endVal"]').val($("#end").val());
	}

  if($("#post").length == 0) { //if post type selection isn't exist
    postval=7;
  }else{
    postval=$('#post').val();
  }

  if($("#transfer").length == 0) { //if transfer type selection isn't exist
    transferval=7;
  }else{
    transferval=$('#transfer').val();
  }

	initDirections($('#startVal').val(), $('#endVal').val(), postval, transferval);
});

function getLocation(butID){
	var gl = $.confirm({
		type: 'orange',
		columnClass: 'col-md-12',
		//containerFluid: true,
		bgOpacity: 0.8,
		icon: 'fa fa-map-o',
		title: 'Select From Map',
		content: 'url:getlocation.php?butID='+butID,
		onContentReady: function () {
			var self = this;
			self.setContentAppend('<span></span>');
			self.$content.find('#mapcontainer').height(405);
			self.$content.find('#map2').height(405);
			setTimeout(function () {
				self.$content.find('.notif').show();
			}, 1000);
		},
		buttons: {
			OK: {
				text: 'Done',
				btnClass: 'btn-dark',
				action: function () {
					if (this.$content.find('input#selectedLoc').val()=="") {
						this.$content.find('#notif').html("<big><b>PLEASE SELECT A LOCATION</b></big>");
						return false;
					}else if (this.$content.find('input#selectedLoc').val()!="") {
						if(butID == "from"){
							$(".bf3 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text(this.$content.find('input#selectedLoc').val());
							$('input[id="startVal"]').val(this.$content.find('input#selectedLoc').val());
							$('#start').val(this.$content.find('input#selectedLoc').val());
							document.fm.start.options[document.fm.start.selectedIndex].text = this.$content.find('input#selectedLoc').val();
						}else if(butID == "to"){
							$(".bf4 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text(this.$content.find('input#selectedLoc').val());
							$('input[id="endVal"]').val(this.$content.find('input#selectedLoc').val());
							$('#end').val(this.$content.find('input#selectedLoc').val());
							document.fm.end.options[document.fm.end.selectedIndex].text = this.$content.find('input#selectedLoc').val();
						}
						initDirections($('#startVal').val(), $('#endVal').val(), $('#post').val(), $('#transfer').val());
					}
				}
			},
			Cancel: function () {
				// do nothing.
			}
		}
	});
}

$("#startBut").click(function() {
	getLocation("from");
});

$("#endBut").click(function() {
	getLocation("to");
});

function showMsg(ico,tit,msg,but,buts,act,but2,but2s,act2){
	$.confirm({
		type: 'orange',
		columnClass: 'col-md-6 col-md-offset-3',
		bgOpacity: 0.8,
		animation: 'top',
		closeAnimation: 'top',
		icon: ico,
		title: tit,
		content: '<div style="text-align:center; font-weight:bold">'+msg+'</div>',
		buttons: {
			Buton1:{
				text: '<b>'+but+'</b>',
				btnClass: buts,
				action: function(){
					eval(act);
				}
			},
			Buton2:{
				text: '<b>'+but2+'</b>',
				btnClass: but2s,
				action: function(){
					eval(act2);
				}
			}
		}
	});
}

$('#getTaxi').on('click', function () {
	var gk = $.confirm({
		type: 'orange',
		columnClass: 'col-md-8 col-md-offset-2 col-xs-12',
		bgOpacity: 0.8,
		icon: 'fa fa-taxi',
		title: 'Take a Taxi',
		content: function () {
			var self = this;

			return $.ajax({
				dataType: 'HTML',
				type: 'POST',
				url: 'gettaxi.php',
				async: false,
				data: {
					sval: $('#startVal').val(),
					eval: $('#endVal').val(),
					pval: $('#postVal').val(),
					tval: $('#transferVal').val(),
					prval: $('#costVal').val(),
					dateval: $('#datepicker').val(),
					timeval: $('#timepicker').val()
				}
			}).success(function (result) {
				self.setContent(result);
			}).fail(function(){
				self.setContent('An error occured.');
			});
		},
		onContentReady: function () {
			that = this;
			that.setContentAppend('<span></span>');
			gk.$content.find('#butSender, #butReceiver').on('click',function() {
				that.buttons.SendOrder.addClass('btn-success');
			});
		},
		buttons: {
			payOnDelivery: {
				text: 'SEND',
				btnClass: 'btn-dark',
				action: function () {
					this.$content.find('.fullname, .phone, .email, .pickup, .dropoff').removeClass("has-error");
					if (this.$content.find('input#fullname').val().length < 5) {
						this.$content.find('.fullname').addClass("has-error");
						this.$content.find('input#fullname').focus();
						return false;
					}else if (this.$content.find('input#phone').val().length < 7 || isNaN(this.$content.find('input#phone').val().replace(/\D/g,''))) {
						this.$content.find('.phone').addClass("has-error");
						this.$content.find('input#phone').focus();
						return false;
					}else if (this.$content.find('input#email').val().length < 8 || this.$content.find('input#email').val().indexOf('@') < 0 || this.$content.find('input#email').val().indexOf('.') < 0) {
						this.$content.find('.email').addClass("has-error");
						this.$content.find('input#email').focus();
						return false;
					}else if (this.$content.find('textarea#pickup').val().length < 5) {
						this.$content.find('.pickup').addClass("has-error");
						this.$content.find('textarea#pickup').focus();
						return false;
					}else if (this.$content.find('textarea#dropoff').val().length < 5) {
						this.$content.find('.dropoff').addClass("has-error");
						this.$content.find('textarea#dropoff').focus();
						return false;
					}else{
						var sending = $.confirm({
							columnClass: 'col-md-6 col-md-offset-3',
							bgOpacity: 0.8,
							icon: 'fa fa-spinner fa-pulse',
							title: 'Sending',
							content: 'Please wait...',
							buttons: {
								Tamam: {
									btnClass: 'hide',
									text: 'DONE',
									action: function () {
										resetMap();
										initMap();
										gk.close();
									}
								},
								Tamam1: {
									btnClass: 'hide',
									text: 'OK',
									action: function () {
										this.close();
									}
								}
							},
							onContentReady: function () {
								this.buttons.Tamam.disable();
								this.buttons.Tamam1.disable();

								return $.ajax({
									type: 'POST',
									url: 'sendorder.php',
									async: false,
									dataType: 'JSON',
									data: {
										posttype: $('#post').find(":selected").text(),
										transfertype: $('#transfer').find(":selected").text(),
										fullname: gk.$content.find('input#fullname').val(),
										gsmnumber: gk.$content.find('input#phone').val().replace(/\D/g,''),
										emailaddress: gk.$content.find('input#email').val(),
										address1: gk.$content.find('textarea#pickup').val(),
										address2: gk.$content.find('textarea#dropoff').val(),
										dateday: $('input[id="datepicker"]').val(),
										timehour: $('input[id="timepicker"]').val(),
										customernote: gk.$content.find('textarea#notes').val(),
										apoint: $('input[id="startVal"]').val(),
										bpoint: $('input[id="endVal"]').val(),
										cost: $('input#costVal').val(),
									},
									success: function(result) {
										if(result.status=="1"){
											sending.setIcon('fa fa-heart');
											sending.setTitle('Thank you!');
											sending.setContent('We have received your request. We will contact you as soon as possible.');
											sending.buttons.Tamam.removeClass('hide');
											sending.buttons.Tamam.addClass('btn-success');
											sending.buttons.Tamam.enable();

											//$("#normFare").css("text-decoration", "none");
											//$("#normFare").html($("#normFare").html().replace("h7>", "h5>"));
										}else{
											sending.setIcon('fa fa-warning');
											sending.setTitle('Failed');
											sending.setContent('<center>An error occured. Please try again.</center>');
											sending.buttons.Tamam1.removeClass('hide');
											sending.buttons.Tamam1.addClass('btn-warning');
											sending.buttons.Tamam1.enable();
										}
									}
								});
							}
						});
						return false;
					}
					return false;
				}
			},
			Cancel: function () {
				// do nothing.
			},
		}
	});
});

function resetMap(){
	$("#normFare, #route").slideUp("slow");
	$("#bookingButton").fadeOut("fast");
	$(".bf3 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text("From?");
	$(".bf4 .select2-bootstrap-append .select2 .selection .select2-selection .select2-selection__rendered").text("Where?");
	$('input[id="startVal"]').val("");
	$('#start').val("");
	$('input[id="endVal"]').val("");
	$('#end').val("");
	$('#datepicker').val("");
	$('#timepicker').val("");
	$('.select2-single').select2().val('0').trigger("change");
	$('.select2-single').select2({
		theme: "bootstrap",
		width : null,
		minimumResultsForSearch: Infinity,
		containerCssClass: ':all:'
	});
	if($(window).width()>=768 && $("#priceCalc").length == 0){
		$(".booking-form-wrapper").animate({marginTop: '525px'}, "slow");
	}
}
